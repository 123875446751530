export const AUTH_TOKEN_KEY = 'zaytoon-base-api-token'
export const AUTH_USER_KEY = 'zaytoon-base-auth-user'
export const PLAYER_CURRENT_TRACKLIST = 'zaytoon-base-player-current-tracklist'
export const PLAYER_CURRENT_TRACK = 'zaytoon-base-player-current-track'
export const PLAYER_CURRENT_TRACK_ID = 'zaytoon-base-player-current-track-id'
export const PLAYER_CURRENT_TRACK_INDEX = 'zaytoon-base-player-current-track-index'
export const PLAYER_CURRENT_TRACKLIST_NAME = 'zaytoon-base-player-current-tracklist-name'
export const PLAYER_SHOW_MINIMIZED_PLAYER = 'zaytoon-base-player-show-minimized-player'
export const PLAYER_OPTION_REPEAT = 'zaytoon-base-player-option-repeat'
export const PLAYER_OPTION_SHUFFLE = 'zaytoon-base-player-option-shuffle'
export const PLAYER_IS_PLAYING = 'zaytoon-base-player-is-playing'