<template>
  <!-- <router-link :to="{ name: 'Category', params: { id: category.id } }"> -->
    
    <div class="collection">
      <div class="title font-weight-bold h6 my-3 mt-2 px-4" v-if="collection && ! collection.hide_title">
        {{ section.title ?? '' }}
        <!-- <router-link :to="{ name: 'Category', params: { id: categories[0].id } }" v-if="categories.length" class="text-success float-end font-weight-normal" -->
          <!-- ><small>مشاهدة المزيد</small></router-link> -->
      </div>

    </div>

    <div class="mb-5">
      <div v-if="$apollo.queries.collection.loading">
        
        <div v-if="section.content_type === 'Category'">
          <div class="vertical-scroll d-flex hide-scrollbar ps-4">
            <CategoryCardLoader />
          </div>
        </div>

        <div v-if="section.content_type === 'Program'">
          <div class="ps-4" v-if="section.layout === 'horizontal_cards'">
            <HorizontalProgramCardLoader />
          </div>
          <div v-else class="vertical-scroll d-flex hide-scrollbar ps-4" >
            <VerticalCardLoader />
          </div>
        </div>

        <div v-if="section.content_type === 'Track'">
          <div class="ps-4 mb-4" v-if="section.layout === 'horizontal_cards'">
            <HorizontalCardLoader />
          </div>
          <div v-else class="vertical-scroll d-flex hide-scrollbar ps-4" >
            <VerticalCardLoader />
          </div>
        </div>

      </div>

      <div v-else>

        <div v-if="collection.content_type === 'Category'">
          <div class="categories">
            <div class="vertical-scroll d-flex hide-scrollbar ps-2">
              <CategoryCard v-for="category in collection.collection_items.map(i => i.collectable)" :key="category.id" :category="category"/>
            </div>
          </div>
        </div>

        <div v-if="collection.content_type === 'Track'">
          <div class="tracks">
              <div class="mb-4 px-2" v-if="collection.layout === 'horizontal_cards'">
                <div class="" v-for="track in collection.collection_items.map(i => i.collectable)" :key="track.id">
                  <TrackHorizontalCard :track="track" :tracklist="collection.collection_items.map(i => i.collectable)" />
                </div>
              </div>

              <div class="vertical-scroll d-flex hide-scrollbar ps-2 pb-3" v-else>
                <div class="" v-for="track in collection.collection_items.map(i => i.collectable)" :key="track.id">
                  <TrackVerticalCard :track="track" :tracklist="collection.collection_items.map(i => i.collectable)" />
                </div>
              </div>
          </div>
        </div>

        <div v-if="collection.content_type === 'Program'">
          <div class="programs">
            <div class="mb-4 ps-2" v-if="collection.layout === 'horizontal_cards'">
              <div class="" v-for="program in collection.collection_items.map(i => i.collectable)" :key="program.id">
                <ProgramHorizontalCard :program="program" />
              </div>
            </div>
            <div class="vertical-scroll d-flex hide-scrollbar ps-2 pb-3" v-else>
              <div class="" v-for="program in collection.collection_items.map(i => i.collectable)" :key="program.id">
                <ProgramVerticalCard :program="program" />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  <!-- </router-link> -->
</template>

<script>
import gql from "graphql-tag";
import CategoryCardLoader from './Loader/CategoryCardLoader.vue';
import CategoryCard from './CategoryCard.vue';
import HorizontalCardLoader from './Loader/HorizontalCardLoader.vue';
import VerticalCardLoader from './Loader/VerticalCardLoader.vue';
import ProgramVerticalCard from './ProgramVerticalCard.vue';
import TrackHorizontalCard from './TrackHorizontalCard.vue';
import TrackVerticalCard from './TrackVerticalCard.vue';
import ProgramHorizontalCard from './ProgramHorizontalCard.vue';
import HorizontalProgramCardLoader from './Loader/HorizontalProgramCardLoader.vue';

export default {
  components: { CategoryCardLoader, CategoryCard, HorizontalCardLoader, VerticalCardLoader, ProgramVerticalCard, TrackHorizontalCard, TrackVerticalCard, ProgramHorizontalCard, HorizontalProgramCardLoader },
  props: ['section'],

  data: () => {
    return {
      collection: {},
    };
  },

  apollo: {
    collection: {
      query: gql`
        query Property($slug: String!) {
          collection (slug: $slug) {
            slug
            title
            layout
            content_type
            hide_title

            collection_items {
              id
              collectable {
                __typename
                ... on Track {
                  id
                  name_ar
                  description_ar
                  description_en
                  thumbnail_url
                  cover_url
                  full_url

                  authors {
                    id
                    name_ar
                    name_en
                  }

                  categories {
                    id
                    name_ar
                  }
                }
                ... on Program {
                  id
                  name_ar
                  thumbnail_url
                  cover_url
                }
                ... on Category {
                  id
                  name_ar
                  name_en
                  icon_url
                }
              
              }
            }
          }
        }
      `,
      variables () {   
        return {
            slug: this.section.slug, 
        }
      },
    },
  }
};
</script>

<style></style>
