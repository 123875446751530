<template>
  <div class="main-navbar">
    <div class="row justify-content-center mx-0">
      <div class="col-md-9">
        <nav class="navbar navbar-expand-lg shadow-0">
          <div class="nav-side-icons right-side col px-2 d-md-none" v-if="$store.getters.isLoggedIn">
            <router-link :to="{ name: 'Profile' }" role="button" class="ripple ripple-surface">
              <i class="la la-user-circle la-2x py-3 px-1 text-light"></i>
            </router-link>
            <router-link :to="{ name: 'Notifications' }" role="button" class="ripple ripple-surface">
              <i class="la la-bell la-2x py-3 px-1 text-light">
                <span class="notifications-count" dir="ltr" v-if="$store.getters.unreadNotificationsCount">
                  <span v-if="$store.getters.unreadNotificationsCount <= 9">{{ $store.getters.unreadNotificationsCount }}</span>
                  <span v-else>+9</span>
                </span>
              </i>
            </router-link>
            <!-- <a
            href="#"
            role="button"
            class=""
            data-bs-target=".collapse"
            data-bs-toggle="collapse"
            aria-expanded="true"
          >
            <i class="la la-bars la-lg p-3 text-light"></i>
          </a> -->
          </div>
          <div class="navbar-brand col justify-content-start d-md-none">
            <router-link :to="{ name: 'Home' }" class="">
              <img src="@/assets/images/logos/logo.png" alt="zaytoon-base" class="mx-2" />
            </router-link>
          </div>
          <div class="nav-side-icons left-side px-2 d-md-none">
            <template v-if="operator === 'zain'">
              <div class="provider-logo d-flex flex-column justify-content-center">
                <img src="@/assets/images/logos/zain.png" alt="" class="zain-logo">
              </div>
            </template>
          </div>
        </nav>
        <div class="d-sm-block d-md-flex mb-3 mb-md-0">
          <div class="col">
            <form action="" @submit.prevent="search" class="col">
              <div class="px-2 pb-3 nav-search">
                <input
                  type="text"
                  class="form-control search-input py-2 text-light"
                  v-model="q"
                  placeholder="ما الذي تبحث عنه؟"
                />
                <i class="search-icon la la-search text-light pt-1" @click="search"></i>
              </div>
            </form>
          </div>
          <div class="d-flex justify-content-center">
            <div v-if="!$store.getters.isLoggedIn" class="ms-2 me-2">
              <router-link :to="{ name: 'Login' }" class="btn text-warning shadow-0 subscribe-now-button fs-5 py-2 border-success bordered">تسجيل الدخول</router-link>
            </div>
            <div class="ms-2 me-2" v-if="operator === 'zain'">
              <button v-if="$store.getters.isLoggedIn" @click="unsubscribe" class="btn text-warning shadow-0 subscribe-now-button fs-5 py-2 border-success bordered" :disabled="unsubscriptionLoading"><i class="la la-spinner la-spin me-2" v-if="unsubscriptionLoading"></i> إلغاء اﻹشتراك</button>
              <a  v-else :href="zainLandingPage" class="btn btn-success shadow-0 subscribe-now-button fs-5 py-2 border-success bordered">اﻹشتراك</a>
            </div>
            <div class="d-none d-md-block mx-md-3">
                <router-link :to="{ name: 'Profile' }" role="button" class="ripple ripple-surface">
                  <i class="la la-user-circle la-3x text-light"></i>
                </router-link>
                <router-link :to="{ name: 'Notifications' }" role="button" class="ripple ripple-surface" v-if="$store.getters.isLoggedIn">
                  <i class="la la-bell la-3x text-light">
                    <span class="notifications-count" dir="ltr" v-if="$store.getters.unreadNotificationsCount">
                      <span v-if="$store.getters.unreadNotificationsCount <= 9">{{ $store.getters.unreadNotificationsCount }}</span>
                      <span v-else>+9</span>
                    </span>
                  </i>
                </router-link>
            </div>
            <div class="provider-logo d-none d-md-block ">
                <div class="cover d-flex flex-column justify-content-center">
                  <template v-if="operator === 'zain'">
                    <img src="@/assets/images/logos/zain.png" alt="" class="zain-logo">
                  </template>

                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

export default {

  data () {
    return {
      q: '',
      operator: process.env.VUE_APP_PORTAL_OPERATOR,
      zainLandingPage: process.env.VUE_APP_ZAIN_LANDING_PAGE_URL,
      unsubscriptionLoading: false,
    }
  },

  setup() {
    const store = useStore()
    const toast = useToast()

    const logout = () => {
      store.dispatch('LOGOUT_USER')
      store.commit("UPDATE_MINIMIZED_PLAYER_SHOW", false);
      store.commit('CLOSE_TRACKLIST', {})
      // router.push({ name: 'LoginInfo' })
      window.location.assign('/')
    }

    return {
      toast,
      logout,
    }
  },

  mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.q = params.q
  },

  methods: {
    search () {
      this.$router.push({ name: 'Search', query: { q: this.q }})
    },
    
    unsubscribe() {
      if(window.confirm('هل تريد إلغاء اﻹشتراك حقا؟')) {
        this.unsubscribeUser()
      }
    },

    unsubscribeUser() {
      this.unsubscriptionLoading = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation {
              unsubscribeUser {
                message
                code
                success
                payload
              }
            }
          `,
          // Parameters
          variables: {
            //
          },
        })
        .then((data) => {
          // Result
          console.log(data.data.unsubscribeUser)
          if(data.data.unsubscribeUser.success) {
            this.toast.success(data.data.unsubscribeUser.message)
            this.logout();
          }else {
            this.toast.error(data.data.unsubscribeUser.message)
          }
          this.unsubscriptionLoading = false;
        })
        .catch((error) => {
          console.log(error)
          this.unsubscriptionLoading = false
          this.reportError()
        });
    },

    reportError(error) {
      let { graphQLErrors } = error;

      if (graphQLErrors && graphQLErrors[0].extensions.category === "authentication") {
        this.toast.warning('إنتهت صلاحية الجلسة، الرجاء إعادة تسجيل الدخول')

        this.logout()
      }
    }
  },
};
</script>

<style scoped>

  .la.la-bell .notifications-count {
    font-size: 12px;
    position: absolute;
    background-color: red;
    min-width: 12px;
    border-radius: 10px;
    left: 8px;
    bottom: 20px;
    /* border: 2px solid #FFF; */
    padding: 2px 4px;
    text-align: center;
  }

  @media (max-width: 966px) {
    .la.la-bell .notifications-count {
      left: 4px;
      bottom: 27px;
    }
  }

</style>
