<template>
  <div class="d-flex w-100 h-100">
    <!-- fixed sidebar -->
    <div
      class="navbar-dark bg-brand position-fixed h-100 w-sidebar collapse show"
      style=""
    >
      <div class="navbar-brand col justify-content-center">
        <router-link :to="{ name: 'Home' }" class="">
          <img src="@/assets/images/logos/logo.png" alt="zaytoon-base" class="" />
        </router-link>
      </div>
      <!-- <ul class="nav flex-column flex-nowrap text-truncate px-2">
        <li class="nav-item">
          <a class="nav-link active" href="#">Active</a>
        </li>
        <li class="nav-item"><a class="nav-link" href="#">Link</a></li>
        <li class="nav-item"><a class="nav-link" href="#">Link</a></li>
      </ul> -->
      <div class="px-3 pt-5">
        <router-link :to="{ name: 'Home' }" class="col d-block item mb-4 text-light ripple ripple-surface">
          <div class="text-center icon d-inline-block">
            <i class="la la-2x la-home"></i>
          </div>
          <div class="text-center label d-inline-block mx-3 h4">الرئيسية</div>
        </router-link>
        <router-link :to="{ name: 'Categories' }" class="col d-block item mb-4 text-light ripple ripple-surface">
          <div class="text-center icon d-inline-block">
            <i class="la la-2x la-table"></i>
          </div>
          <div class="text-center label d-inline-block mx-3 h4">اكتشف</div>
        </router-link>
        <router-link :to="{ name: 'Authors' }" class="col d-block item mb-3 text-light ripple ripple-surface">
          <div class="text-center icon d-inline-block">
            <i class="la la-2x la-microphone"></i>
          </div>
          <div class="text-center label d-inline-block mx-3 h4">المتحدثون</div>
        </router-link>
        <router-link :to="{ name: 'Favorite' }" class="col d-block item mb-3 text-light ripple ripple-surface">
          <div class="text-center icon d-inline-block">
            <i class="la la-2x la-heart-o"></i>
          </div>
          <div class="text-center label d-inline-block mx-3 h4">المفضلة</div>
        </router-link>
        <router-link :to="{ name: 'Profile' }" class="col d-block item mb-3 text-light ripple ripple-surface">
          <div class="text-center icon d-inline-block">
            <i class="la la-2x la-user-circle"></i>
          </div>
          <div class="text-center label d-inline-block mx-3 h4">حسابي</div>
        </router-link>
      </div>
    </div>
    <div
      class="col h-100"
      data-bs-toggle="collapse"
      href="#"
      data-bs-target=".collapse"
      role="button"
      aria-expanded="true"
    ></div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
