import { createStore } from "vuex";
import { 
  AUTH_TOKEN_KEY, 
  AUTH_USER_KEY,
  PLAYER_CURRENT_TRACKLIST, 
  PLAYER_CURRENT_TRACK, 
  PLAYER_CURRENT_TRACK_ID,
  PLAYER_CURRENT_TRACK_INDEX,
  PLAYER_CURRENT_TRACKLIST_NAME,
  // PLAYER_IS_PLAYING,
  PLAYER_SHOW_MINIMIZED_PLAYER,
  PLAYER_OPTION_REPEAT,
  PLAYER_OPTION_SHUFFLE,
 } from '../constants'

export default createStore({
  state: {
    user: null,
    token: localStorage.getItem(AUTH_TOKEN_KEY),
    player_is_playing: false,
    player_option_repeat: JSON.parse(localStorage.getItem(PLAYER_OPTION_REPEAT)) ?? false,
    player_option_shuffle: JSON.parse(localStorage.getItem(PLAYER_OPTION_SHUFFLE)) ?? false,
    current_track: JSON.parse(localStorage.getItem(PLAYER_CURRENT_TRACK)),
    current_track_id: JSON.parse(localStorage.getItem(PLAYER_CURRENT_TRACK_ID)),
    current_tracklist: JSON.parse(localStorage.getItem(PLAYER_CURRENT_TRACKLIST)) ?? [],
    current_track_index: JSON.parse(localStorage.getItem(PLAYER_CURRENT_TRACK_INDEX)) ?? 0,
    current_tracklist_name: localStorage.getItem(PLAYER_CURRENT_TRACKLIST_NAME) ?? null,
    showMinimizedPlayer: JSON.parse(localStorage.getItem(PLAYER_SHOW_MINIMIZED_PLAYER)),
    showPlayedTracklistModal: false,
    unreadNotificationsCount: 0,
  },
  getters: {
    user: (state) => {
      return JSON.parse(localStorage.getItem(AUTH_USER_KEY))
    },
    playerIsPlaying: (state) => {
      return state.player_is_playing
    },
    currentTrackId: (state) => {
      return state.current_track_id
    },
    currentTrack: (state) => {
      return state.current_track
    },
    currentTracklist: (state) => {
      return state.current_tracklist
    },
    currentTrackIndex: (state) => {
      return state.current_track_index
    },
    currentTracklistName: (state) => {
      return state.current_tracklist_name
    },
    showMinimizedPlayer: (state) => {
      return state.showMinimizedPlayer
    },
    playerOptionRepeat: (state) => {
      return state.player_option_repeat
    },
    playerOptionShuffle: (state) => {
      return state.player_option_shuffle
    },
    getToken(state) {
      return localStorage.getItem(AUTH_TOKEN_KEY)
    },
    isLoggedIn(state) {
      return localStorage.getItem(AUTH_TOKEN_KEY)
    },
    unreadNotificationsCount (state) {
      return state.unreadNotificationsCount
    }
  },
  mutations: {
    SET_USER (state, user) {
      localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user))
    },
    SET_TOKEN (state, token) {
      localStorage.setItem(AUTH_TOKEN_KEY, token)
    },
    SET_IS_PLAYING (state, payload) {
      state.player_is_playing = payload
    },
    SET_TRACKLIST (state, payload) {
      state.current_tracklist = payload.tracklist
      state.current_tracklist_name = payload.tracklist_name
      state.current_track = payload.track
      state.current_track_id = payload.track.id
      state.current_track_index = payload.index
      localStorage.setItem(PLAYER_CURRENT_TRACKLIST, JSON.stringify(payload.tracklist))
      localStorage.setItem(PLAYER_CURRENT_TRACKLIST_NAME, payload.tracklist_name)
      localStorage.setItem(PLAYER_CURRENT_TRACK, JSON.stringify(payload.track))
      localStorage.setItem(PLAYER_CURRENT_TRACK_ID, JSON.stringify(payload.track.id))
      localStorage.setItem(PLAYER_CURRENT_TRACK_INDEX, JSON.stringify(payload.index))
    },
    CLOSE_TRACKLIST (state, payload) {
      state.current_tracklist = []
      state.current_tracklist_name = null
      state.current_track = {}
      state.current_track_id = undefined
      state.current_track_index = undefined
      localStorage.setItem(PLAYER_CURRENT_TRACKLIST, JSON.stringify([]))
      localStorage.setItem(PLAYER_CURRENT_TRACK, JSON.stringify({}))
      localStorage.setItem(PLAYER_CURRENT_TRACK_ID, JSON.stringify(''))
      localStorage.setItem(PLAYER_CURRENT_TRACK_INDEX, JSON.stringify(''))
    },
    SET_TRACK (state, payload) {
      if (state.current_track_id != payload.track.id) {
        state.current_track = payload.track
        state.current_track_id = payload.track.id
        state.current_track_index = payload.index
        localStorage.setItem(PLAYER_CURRENT_TRACK, JSON.stringify(payload.track))
        localStorage.setItem(PLAYER_CURRENT_TRACK_ID, JSON.stringify(payload.track.id))
        localStorage.setItem(PLAYER_CURRENT_TRACK_INDEX, JSON.stringify(payload.index))
      }
    },
    UPDATE_MINIMIZED_PLAYER_SHOW(state, payload) {
      state.showMinimizedPlayer = payload
      localStorage.setItem(PLAYER_SHOW_MINIMIZED_PLAYER, JSON.stringify(payload))
    },
    UPDATE_PLAYER_OPTION_REPEAT(state, payload) {
      state.player_option_repeat = payload
      localStorage.setItem(PLAYER_OPTION_REPEAT, JSON.stringify(payload))
    },
    UPDATE_PLAYER_OPTION_SHUFFLE(state, payload) {
      state.player_option_shuffle = payload
      localStorage.setItem(PLAYER_OPTION_SHUFFLE, JSON.stringify(payload))
    },
    UPDATE_PLAYED_TRACKLIST_MODAL_SHOW(state, payload) {
      state.showPlayedTracklistModal = payload
    },
    UPDATE_UNREAD_NOTIFICATIONS_COUNT(state, payload) {
      state.unreadNotificationsCount = payload
    }
  },
  actions: {
    LOGOUT_USER (context) {
      localStorage.removeItem(AUTH_USER_KEY)
      localStorage.removeItem(AUTH_TOKEN_KEY)
    },
  },
  modules: {},
});
